@import 'src/styles/breakpoints.module';

.layoutCards {
  display: none;

  @include tablet {
    grid-template-columns: 1fr 1fr;
    border: 1px solid var(--palette-gray-600);
    border-radius: 12px;
    display: grid;
  }
  @include desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
