@import '@/styles/breakpoints.module';

.appBtn.appBtn {
  svg {
    flex-shrink: 0;
  }

  span {
    text-align: center;
  }

  //@include desktop {
  //  min-width: 273px;
  //}
}
