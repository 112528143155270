@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';
@import '@/styles/link.module';

@mixin assetDescriptionTypography {
  @include bodyM_;

  strong {
    font-weight: var(--palette-font-weight-semibold);
  }

  h2,
  h3,
  h4 {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  h2 {
    @include _h5;
  }

  h3 {
    @include _h6;
  }

  a {
    @include textLink_;
  }

  strong {
    color: var(--color-text-primary);
    font-weight: var(--palette-font-weight-bold);
  }

  p,
  ol,
  ul {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  ol,
  ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-inline-start: 22px;

    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
