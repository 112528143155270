@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.tradingData {
  margin-top: 32px;

  @include tablet {
    margin-top: 24px;
  }

  @include desktop {
    margin-top: 32px;
  }
}

.tradingDataRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include desktop {
    flex-direction: row;
  }
  & > * {
    @include desktop {
      max-width: 334px;
    }
    width: 100%;
  }
}
