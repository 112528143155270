@import '@/styles/breakpoints.module';

.wrapper {
  border-radius: var(--palette-border-radius-m);
  background: var(--palette-white);
  box-shadow: var(--shadow-converters-bonus);
  padding: 16px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .content {
    max-width: 100%;
    width: 100%;

    @include desktop {
      max-width: 420px;
    }
  }

  .subtitle {
    margin-top: 12px;
    color: var(--palette-gray-600);
  }

  @include tablet {
    padding: 40px;
  }

  @include desktop {
    max-width: 540px;
    padding: 60px 24px;
  }
}
