@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.selectDropdown {
  :global .MuiPaper-root {
    border-radius: 8px;
    box-shadow: 0px 0px 30px 0px rgba(11, 11, 11, 0.2);
    margin-top: 4px;

    :global .MuiList-root {
      border-radius: 8px;
      border: 1.5px solid var(--greyscale-grey-background-95, #f2f2f2);

      :global .MuiMenuItem-root {
        padding: 12px 16px;
        padding-inline-start: 12px;

        &:global(.Mui-selected) {
          background-color: var(--palette-primary-700);
          color: var(--color-text-primary);
        }

        & > div {
          width: 16px;
          height: 16px;
          margin-inline-end: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 100%;
            height: auto;
          }
        }
      }

      color: var(--palette-gray-900);
      @include typography.bodyM_;
    }
  }
}

.select {
  outline: none;

  :global .MuiSelect-select {
    color: var(--palette-gray-900);
    @include typography.bodyM_;
    padding: 8px 12px;
    padding-inline-end: 36px;
    div {
      display: none;
    }
  }

  :global .MuiSelect-icon {
    color: var(--palette-gray-900);
  }

  :global .MuiOutlinedInput-notchedOutline {
    border-radius: var(--spacing-8-px, 8px);
    border: 1px solid var(--palette-gray-150);
  }

  &:hover,
  &:global(.Mui-focused) {
    :global .MuiOutlinedInput-notchedOutline {
      border: 1px solid var(--palette-primary-700);
    }
  }
}
