@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';
@import './assetDescriptionTypography.module';
@import '@/styles/link.module';

.aboutTab {
  position: relative;
  cursor: pointer;

  .aboutTabButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background-color: white;

    &.shadow {
      box-shadow: 0px 0px 30px 0px rgba(11, 11, 11, 0.15);
      clip-path: inset(-100px 0 0);
      width: calc(100% + 32px);
      margin-inline-start: -16px;
      @include tablet {
        width: calc(100% + 16px);
        margin-inline-start: -8px;
      }
    }
  }

  & > :first-child {
    margin-bottom: 20px;
  }

  .aboutTabText {
    max-height: 150px;
    overflow: hidden;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      bottom: 44px;
      height: 100%;
      background: linear-gradient(0deg, white, #ffffff30);
    }
    @include assetDescriptionTypography;
  }

  &.expanded {
    cursor: auto;
    .aboutTabText {
      max-height: none;
      &:before {
        display: none;
      }
    }
    .aboutTabButtonContainer {
      position: sticky;
      bottom: 0px;
      button svg {
        transform: rotate(180deg);
      }
    }
  }
}

.statsTabContent {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }

  .changeIntervals {
    padding: 16px;
    background-color: var(--palette-gray-50);
    border-radius: var(--palette-border-radius-s);

    table {
      td,
      th {
        padding: 16px 12px;

        &:first-child {
          text-align: start;
        }

        &:last-child {
          text-align: end;
        }
      }

      tr {
        &:not(:last-child) {
          border-bottom: 1px solid var(--palette-gray-125);
        }
      }
    }
  }

  .statsTabContentWidget {
    height: 418px !important;
    min-height: 418px !important;
    flex-grow: 1;
    border-radius: var(--palette-border-radius-s);
    border: 1px solid var(--palette-gray-125);
  }
}

.coinTabsSection {
  :global .MuiTabPanel-root {
    padding: 0px;
  }
}

//.statisticsGrid {
//  display: grid;
//  grid-template-columns: 1fr 1fr;
//  row-gap: 16px;
//
//  @include tablet {
//    grid-template-columns: 1fr 1fr 1fr;
//  }
//
//  & > * {
//    border-bottom: 1px solid var(--palette-gray-100);
//    display: flex;
//    flex-direction: column;
//    gap: 8px;
//    padding-bottom: 16px;
//    & > :first-child {
//      color: var(--palette-gray-500);
//    }
//    & > :last-child {
//      color: var(--color-text-primary);
//      font-weight: var(--palette-font-weight-semibold);
//    }
//    &:last-child {
//      grid-column: span 2;
//      @include tablet {
//        grid-column: auto;
//      }
//    }
//  }
//}
