@import '@/styles/breakpoints.module';

.heroSection {
  overflow-wrap: anywhere;
  --hero-section-gap: 40px;
  padding-top: calc(var(--height-header) + var(--height-second-nav-row) + 24px + var(--height-app-banner));
  padding-bottom: 40px;

  .mainBlockLayout {
    display: flex;
    flex-direction: column;
    --hero-section-main-block-gap: 24px;
    gap: var(--hero-section-main-block-gap);

    @include desktop {
      align-items: flex-start;
    }
    @include tablet {
      flex-direction: row;
    }
    @include desktop {
      gap: 30px;
    }

    & > a {
      flex-shrink: 0;
      margin-top: calc(var(--hero-section-gap) - var(--hero-section-main-block-gap));

      @include tablet {
        display: none;
      }

      @include desktop {
        margin-top: 0;
        display: inline-flex;
        margin-inline-start: auto;
      }
    }

    .avatarAndCta {
      display: flex;
      justify-content: center;

      @include tablet {
        align-items: center;
        flex-direction: column;
      }

      a {
        display: none;
        @include tablet {
          display: inline-flex;
          margin-top: var(--hero-section-main-block-gap);
        }
        @include desktop {
          display: none;
        }
      }
    }
  }
}
