@import '@/styles/breakpoints.module';

.cardTradingConditions {
  padding: 24px 16px;
  .cardHeaderBlock {
    margin-bottom: 16px;
    @include tablet {
      margin-bottom: 24px;
    }
  }
  @include tablet {
    padding: 24px;
  }
}

.platformSelectLabel {
  font-weight: var(--palette-font-weight-medium);
  margin-bottom: 8px;
  display: block;
}

.tradeConditionsBlockLayout {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include desktop {
    flex-direction: row;
    gap: 24px;
  }
}

.selectAndTableContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  @include desktop {
    //max-width: 726px;
  }
}

.table {
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    gap: 12px;
    @include tablet {
      padding: 16px;
    }
    &:nth-child(odd) {
      background-color: var(--palette-gray-70);
    }

    .sep {
      flex-grow: 1;
      height: 1px;
      border: 1px dashed var(--palette-gray-150);
    }

    .fieldValue {
      color: var(--color-text-primary);
    }
  }
}

.card {
  flex-shrink: 0;
  background-color: var(--palette-gray-70);
  padding: 16px;
  gap: 16px;
  border-radius: var(--palette-border-radius-s);
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include desktop {
    padding: 24px;
    gap: 24px;
    margin-top: 24px;
  }
  .row {
    .fieldName {
      margin-bottom: 8px;
    }
    .fieldValue {
      color: var(--color-text-primary);
    }
  }
}

.disclaimer{
  margin-top: 24px;
}
