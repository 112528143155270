@use '@/styles/rtl.module';
@import '@/styles/breakpoints.module';

.secondNavRow {
  background-color: var(--palette-gray-600);
  padding-block-start: 6px;
  padding-block-end: 6px;

  display: none;
  position: relative;
  z-index: -1;

  & > div {
    display: flex;
  }

  @include desktop {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 100%;
    inset-inline-end: 0;
    top: 0;
    display: block;
    z-index: 1;

    @include rtl.rtl(
      'background',
      linear-gradient(to left, #383838 0%, rgba(56, 56, 56, 0) 100%),
      linear-gradient(to right, #383838 0%, rgba(56, 56, 56, 0) 100%)
    );
  }
}

.visible {
  display: block;
}

.secondNavRowContainer {
  padding-inline-end: 0;
}

.secondNavRowItemsContainer {
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-block: hidden;

  @include desktop {
    overflow: visible;
  }
}
