.arrowOpen {
  transition: all 0.3s ease;
  transform: rotate(180deg);
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
