@import '@/styles/breakpoints.module';

.wrapperBtn {
  width: 100%;

  @include tablet {
    width: initial;
  }
}

.disclaimer {
  align-self: center;
  color: var(--palette-gray-500) !important;
}
