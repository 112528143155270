@import '@/styles/breakpoints.module';

.slide.slide {
  width: 100%;
  height: 472px;
  pointer-events: auto;

  @include tablet {
    height: 520px;
  }

  @include desktop {
    width: 360px;
  }
}
