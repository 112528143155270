@import '@/styles/breakpoints.module';

.strategyInfoConatiner {
  display: flex;
  --strategy-info-conatiner-gap: 16px;
  @include desktop {
    --strategy-info-conatiner-gap: 20px;
  }
  gap: var(--strategy-info-conatiner-gap);
  flex-direction: column;

  .strategyInfoHeader {
    text-align: center;
    @include tablet {
      text-align: start;
    }
    h1 {
      margin-bottom: 2px;
    }
  }

  .bioDescription {
    color: var(--color-text-primary);
    text-align: center;
    @include tablet {
      text-align: start;
    }
  }
}

.strategyInfoStatsRow {
  display: flex;
  justify-content: space-between;
  margin-top: calc(24px - var(--strategy-info-conatiner-gap));
  width: 100%;

  @include tablet {
    justify-content: flex-start;
  }

  & > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      text-align: start;
      padding: 5px 0;
    }
    dd {
      font-weight: var(--palette-font-weight-semibold);
      margin-top: 6px;
    }

    dt {
      color: var(--color-text-primary);
    }
  }

  .strategyInfoStatsRowSep {
    width: 1px;
    min-height: 100%;
    background-color: var(--palette-gray-500);
    margin: 0 4px;
    @include tablet {
      margin: 0 24px;
    }
  }
}
