@import '@/styles/breakpoints.module';

.cardWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--palette-white);
  border-radius: var(--palette-border-radius-s);
  box-shadow: 2px 4px 60px 0 var(--copy-trade-shadow);

  --strategy-card-badge-offset: 35px;
  margin-top: calc(var(--strategy-card-badge-offset));
  height: calc(100% - var(--strategy-card-badge-offset));
  width: 100%;

  padding: 16px;
  gap: 16px;
  overflow-wrap: anywhere;

  @include tablet {
    padding: 24px;
    gap: 24px;
  }
}

.cardFooter {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include tablet {
    gap: 24px;
  }
}

.cardAccountInfoCredetials {
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    height: 20px;
    width: 20px;
  }
}

.badge {
  position: absolute;
  inset-inline-end: 24px;
  top: calc(var(--strategy-card-badge-offset) * -1);
  width: 68px;
  height: 69px;
}

.badgeContour {
  position: absolute;
}

.badgeDark {
  color: var(--palette-primary-700);

  .badgeContent {
    color: var(--palette-white);
  }
}

.badgeLight {
  color: var(--palette-primary-100);

  .badgeContent {
    color: var(--palette-primary-700);
  }
}

.badgeContent {
  position: relative;
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

  p {
    color: inherit;
    font-weight: var(--palette-font-weight-bold);
  }
}

.badgeStars {
  display: flex;
  justify-content: center;
  gap: 2px;
}

.cardAccount {
  margin-top: 12px;
  display: flex;
  gap: 16px;
}

.cardAccountAvatar {
  max-width: 90px !important;
  min-width: 90px !important;
}

.cardAccountInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.cardAccountInfoName {
  font-weight: var(--palette-font-weight-semibold);
  color: var(--palette-gray-900);
}

.cardAccountInfoFollowers {
  color: var(--palette-gray-400);

  span {
    font-weight: var(--palette-font-weight-semibold);
    color: var(--palette-gray-900);
  }
}

.cardStrategy {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cardStrategyName {
  color: var(--palette-gray-900);
}

.cardStrategyProgressionWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.chart {
  width: 100%;
  height: 50px;
}

.cardStrategyProgressionItem {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 10px;
  border: 1px solid var(--palette-primary-700);
  border-radius: var(--palette-border-radius-xxl);

  .strategyChipName {
    color: var(--palette-gray-400);
  }
}

.strategyChipValue {
  font-weight: var(--palette-font-weight-semibold);
}

.cardViewProfileBtn {
  margin-top: auto;
  width: 100%;
}

.cardAdditionalInfo {
  display: flex;
  gap: 16px;
  padding: 12px 0;
  justify-content: center;

  :not(.separator) {
    width: 100%;
    white-space: nowrap;
  }
}

.cardAdditionalInfoItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  text-align: center;
  overflow-wrap: anywhere;

  & > :last-child {
    color: var(--palette-gray-400);
  }

  & > :first-child {
    color: var(--color-text-primary);
  }
}
