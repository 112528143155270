@import '@/styles/breakpoints.module';

.card {
  background-color: var(--palette-white);
  border-radius: 12px;

  .cardHeaderBlock {
    margin-bottom: 24px;
    .cardHeader {
      display: flex;
      svg,
      img {
        margin-inline-end: 12px;
      }
      align-items: center;
      font-weight: var(--palette-font-weight-semibold);
    }
    .cardSubtitle {
      margin-top: 8px;
    }
  }

  &.cardPaddingRegular {
    padding: 16px;
  }
}
